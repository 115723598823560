import styled from "styled-components";
export const Container = styled.div`
    display: inline-flex;
    margin: 6px 1rem;
    flex-wrap: wrap;
    gap: 8px;
`;

export const CardTitle = styled.p`
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
    padding: 0;
`

export const Link = styled.a`
    text-decoration: none;
    color: inherit;
`;
export const Card = styled.div`
    display: flex;
    padding: 8px;
    border-radius: 16px;
    border-width: 2px;
    align-items: center;
    border-color: ${props => props.theme.primary};
    border-style: solid;
    color: ${props => props.theme.primary};
    gap: 8px;

    transition: 0.3s all ease;
    cursor: ${props => props.clickable ? 'pointer' : 'default'};
    opacity: .6;
    &:hover {
        opacity: .8;
    }
`;

export const CardHeader = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CardDescription = styled.p`
    font-size: .8rem;
    margin: 0;
    padding: 0;
`;
