import styled, { css } from "styled-components";

export const Container = styled.div`
    background-color: ${props => props.theme.backgroundSecondary};
    position: absolute;
    width: 100vw;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @supports(height: 100dvh) {
        height: 100dvh;
    }
    @supports not (height: 100dvh) {
        height: 100vh;    
    }
 `;

export const InputContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
`;

export const RowContainer = styled.div`
    width: 100%;
    display: flex;
    flex: 1;
`

export const RitaName = styled.div`
    color: ${props => props.theme.primary};
    font-size: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    
    @media (max-width: 768px) {
        font-size: 1.5em;
    }
`;

export const CustomLogo = styled.img`
    height: 48px;
    width: 48px;
    margin: 0 16px;
    border-radius: 100%;
`;

export const MessagesContainer = styled.div`
    width: 100%;
    height: ${props => (props.drawer ? 'calc(100% - 92px - 92px - 92px)' : 'calc(100% - 92px - 92px)')};
    overflow-y: scroll;
    word-break: break-word;
    white-space: pre-wrap;
    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: ${props => props.theme.primary};
        border-radius: 10px;
    }

    @media (max-width: 768px) {
        width: 100%;
        height: ${props => (props.drawer ? 'calc(100% - 204px)' : 'calc(100% - 124px)')};
        border-radius: 0;
        font-size: 12px; 
    }

    @media (max-width: 480px) {
        width: 100%;
        height: ${props => (props.drawer ? 'calc(100% - 196px)' : 'calc(100% - 124px)')};
        border-radius: 0;
        font-size: 12px; 
    }
`;

export const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        height: 60px;
    }
`;

export const HeaderTitleContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

export const ChatbotContainer = styled.div`
    background-color: ${props => props.theme.background};
    border-radius: 36px;
    box-shadow: ${props => props.theme.boxShadow};
    height: 90%;
    width: 570px;
    font-size: 1em; 
    
    @media (max-width: 768px) {
        width: 100%;
        height: 100%;
        border-radius: 0;
        font-size: 16px; 
    }
`;

export const Action = styled.button`
    background-color: ${props => props.theme.primary};
    width: 70px;
    height: 70px;
    border-width: 0;
    border-radius: 100%;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 12px;
    transition: transform 0.3s;

    @media (max-width: 768px) {
        width: 50px;
        height: 50px;
        font-size: 16px;
    }
    
    &:hover {
        transform: scale(1.1);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }

`;

export const BtnImage = styled.img`
    width: 28px;
    height: 28px;

    @media (max-width: 480px) {
        width: 20px;
        height: 20px;
    }
`;

export const AttachmentBtn = styled.button`
    position: absolute;
    left: 28px;
    bottom: 7px;
    width: 56px;
    height: 56px;
    border-width: 0;
    border-radius: 100%;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;

    @media (max-width: 768px) {
        width: 40px;
        height: 40px;
        bottom: 6px;
        font-size: 16px;
    }

    ${props =>
        props.disabled
            ? css`
                cursor: default;
                &:hover {
                    transform: none;
                    box-shadow: none;
                }
            `
            : css`
                &:hover {
                    background-color: #ccc;
                    transform: scale(1.1);
                    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
                }
            `
    }
`
export const CheckBtn = styled.button`
    position: absolute;
    right: 102px;
    bottom: 7px;
    width: 56px;
    height: 56px;
    border-width: 0;
    border-radius: 100%;
    outline: none;
    display: ${props => props.isAudio ? 'flex' : 'none'};
    align-items: center;
    justify-content: center;
    cursor: pointer;
    ${props => props.theme.backgroundSecondary};
    transition: background-color 0.3s, transform 0.3s;

    @media (max-width: 768px) {
        bottom: 6px;
        right: 98px;
        width: 40px;
        height: 40px;
        font-size: 16px;
    }
    @media (max-width: 640px) {
        right: 92px;
    }
    @media (max-width: 560px) {
        right: 86px;
    }
    @media (max-width: 480px) {
        right: 84px;
    }
    @media (max-width: 440px) {
        right: 80px;
    }
    @media (max-width: 360px) {
        right: 76px;
    }
    
    &:hover {
        background-color: #ccc;
        transform: scale(1.1);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }
`
export const PreviewImage = styled.img`
    margin: 8px 0 8px 4px;
    width: 60px;
    height: 60px;
    border-radius: 32px;
    display: ${props => props.src ? 'block' : 'none'};
    object-fit: cover;

    @media (max-width: 768px) {
        width: 50px;
        height: 50px;
    }
    @media (max-width: 480px) {
        width: 40px;
        height: 40px;
    }
`;

export const ImageName = styled.div`
    width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${props => props.theme.primary};

    @media (max-width: 480px) {
         width: 65%;
        font-size: 12px;
    }
`

export const ImageDrawer = styled.div`
    display: ${props => (props.open ? 'flex' : 'none')};
    margin-left: 20px;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: calc(79% - 11px);
    height: 100%;
    padding: 8px;
    z-index: 0;
    background-color: ${props => props.theme.backgroundSecondary};
    border-radius: 32px 32px 0 0;
    
    @media (max-width: 768px) {
        width: calc(95% - 88px);
    }
    
`;

export const CloseButton = styled.button`
    background-color: red;
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        width: 32px;
        height: 32px;
        font-size: 16px;
    }
    @media (max-width: 320px) {
        width: 24px;
        height: 24px;
        font-size: 12px;
        top: 4px;
    }
`;

export const AudioInput = styled.div`
    width: calc(95% - 46px - 40px);
    height: 70px;
    box-sizing: border-box;
    margin-left: 20px;
    border-radius: ${props => props.drawer ? '0 0 36px 36px' : '36px'};
    outline: none;
    padding: 12px 72px 0px 116px;
    border-width: 0;
    background: ${props => props.theme.backgroundSecondary};

    @media (max-width: 768px) {
        height: 52px;
        width: calc(95% - 72px);
        padding: 10px 72px 0px 94px;
        font-size: 16px;
    }
    @media (max-width: 480px) {
        width: calc(95% - 72px);
        font-size: 12px;
    }
`
export const Counter = styled.div`
    position: absolute;
    display: ${props => props.isRecording ? 'flex' : 'none'};
    left: 92px;
    bottom: 20px;
    color: #fff;
    padding: 4px;
    background-color: ${(props) => props.theme.primary};
    border-radius: 8px;
    
    @media (max-width: 768px) {
        bottom: 14px;
        left: 76px;
        font-size: 10px;
    }   
`

export const InputField = styled.input`
    width: calc(95% - 46px - 40px);
    height: 70px;
    box-sizing: border-box;
    margin-left: 20px;
    border-radius: ${props => props.drawer ? '0 0 32px 32px' : '36px'};
    trasition: border-radius 0.3s ease;
    outline: none;
    color: ${props => props.theme.primary};
    font-size: 1.2em;
    padding: 0 1rem 0 72px;
    border-width: 0;
    background: ${props => props.theme.backgroundSecondary};

    @media (max-width: 768px) {
        height: 52px;
        width: calc(95% - 72px);
        padding: 0 1rem 0 60px;
        font-size: 16px;
    }
`;
