import { sourceUrl } from '../config';

export default async function DataProvider(route, method, data, query, headers = {}, fileList = [], clientData = {}) {
    let url = new URL(route, sourceUrl);

    headers['Content-Type'] = headers['Content-Type'] ?? 'application/json';
    if (query) {
        Object.keys(query).forEach(key => url.searchParams.append(key, query[key]));
    }

    const message = JSON.stringify({message: data, clientData});

    let request = {
        method,
        headers,
        body: message
    };

    if (fileList.length > 0) {
        const body = new FormData();
        if (data !== "") {
            body.set("message", data);
        }
        for (const fileKey in fileList) {
            let fileType = fileList[fileKey].type.split("/")[0];
            if (fileType === "image" && data === "") {
                body.set("message", "Segue a imagem");
            }
            body.set(`file_${fileKey}`, fileList[fileKey]);
        }
        body.set("fileToText", true);
        delete request.headers['Content-Type'];
        request.body = body;
    }

    return await fetch(url.toString(), request).then(res => res.json());
};
