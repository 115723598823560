export const identifierIsVideo = identifier => identifier.includes("-")

export const formatIdentifier = (identifier) => {
    if (identifierIsVideo(identifier)) {
        const [start, end] = identifier.split(' - ').map(parseFloat);
        const formatTime = (time) => {
            const minutes = Math.floor(time / 60);
            const seconds = Math.round(time % 60);
            return `${minutes}:${seconds.toString().padStart(2, '0')} min`;
        };
        return `${formatTime(start)} - ${formatTime(end)}`;
    }
    return identifier;
};
