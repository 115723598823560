import styled, {keyframes, css} from "styled-components";

const ShowMessageAnimation = keyframes`
    0% {
        max-width: 0;
        max-height: 0;
        font-size: 0;
    }

    20% {
        max-width: 65%;
        font-size: 0;
    }

    50% {
        max-width: 65%;
        max-height: initial;
    }
`;

export const MessageContainer = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 1rem;
    ${props => props.client && css`
        justify-content: flex-end;
    `}
`;

export const MessageBox = styled.div`
    max-width: 65%;
    padding: 8px;

    ${props => props.client ? css`
                background-color: ${props => props.theme.primary};
            `
            : css`
                background-color: ${props => props.theme.secondary};
            `}

    ${props => props.attached ? css`border-radius: 16px;`
            : props.client ? css`border-radius: 16px 16px 0 16px;`
                    : css`border-radius: 0 16px 16px 16px;`}

    margin: 6px 1rem;
    display: flex;
    justify-content: left;
    box-shadow: ${props => props.theme.boxShadow};
    align-items: center;
    animation-direction: alternate;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
    animation-duration: 0.2s;
    animation-name: ${ShowMessageAnimation};

    @media (max-width: 468px) {
        max-width: 75%;
    }
`;
export const ImageContainer = styled.div`
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 90%;
`
export const ImageView = styled.img`
    margin-right: 12px;
    width: 60px;
    height: 60px;
    border-radius: 10px;

    @media (max-width: 768px) {
        width: 65px;
        height: 65px;
    }
    @media (max-width: 480px) {
        width: 70px;
        height: 70px;
    }
`

export const ImageTitle = styled.span`
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${props => props.theme.background};

    @media (max-width: 480px) {
        font-size: 16px;
    }
`
export const AudioContainer = styled.span`
    padding: 8px;
    display: flex;
    jjustify-content: space-between;
    align-items: center;
`

export const AudioBtn = styled.button`
    padding-top: 4px;
    background-color: transparent;
    cursor: pointer;
    border: none;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
`

export const BtnImage = styled.img`
    width: 20px;
    height: 20px;
    @media (max-width: 768px) {
        width: 16px;
        height: 16px;
    }
`;

export const AudioTimer = styled.div`
    color: ${props => props.theme.background};
    width: 50%;
`

export const Message = styled.span`
    ${props => props.client ? css`
                color: ${props => props.theme.background};
            `
            : css`
                color: ${props => props.theme.primary};
            `}
    padding: 8px;
    white-space: pre-line;
    word-wrap: break-word;
    
    p {
        padding: 0;
        margin: 0;
    }

    @media (max-width: 480px) {
        font-size: 16px;
    }
`;
