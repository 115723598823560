import React from "react";
import {Container, BigBall, SmallBall} from './styles'

export default function RitaStatus(props) {
    return (
        <Container>
            <BigBall {...props}>
                <SmallBall {...props}/>
            </BigBall>
        </Container>
    );
};