import React from "react";
import {Message, MessageBox, MessageContainer} from "./styles";
import Markdown from 'react-markdown'

export default function TextMessage(props) {
    let {message} = props;

    function LinkRenderer(props) {
        return (
            <a href={props.href} target="_blank" rel="noreferrer">
                {props.children}
            </a>
        );
    }

    return (
        <MessageContainer {...props}>
            <MessageBox {...props}>
                <Message {...props}>
                    <Markdown components={{a: LinkRenderer}}>
                        {message}
                    </Markdown>
                </Message>
            </MessageBox>
        </MessageContainer>
    );

};
