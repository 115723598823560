import React from "react";
import {Card, CardDescription, CardHeader, CardTitle, Container, Icon, Link} from "./styles";
import {formatIdentifier, identifierIsVideo} from "../../../utils/data-converter";
import {useTheme} from "styled-components";

export default function ({source}) {
    const theme = useTheme()

    const Document = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                          stroke="currentColor" className="w-2 h-2" style={{height: 24}}>
        <path color={theme.primary} stroke-linecap="round" stroke-linejoin="round"
              d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"/>
    </svg>;

    const Video = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                       stroke="currentColor" className="w-2 h-2" style={{height: 24}}>
        <path color={theme.primary} stroke-linecap="round" stroke-linejoin="round"
              d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z"/>
    </svg>;

    const Url = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6" style={{height: 24}}>
    <path stroke-linecap="round" stroke-linejoin="round" d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244" />
  </svg>
  
  const isHttp = (title) => {
    return title.startsWith('http')
    }

    return (
        <Container>
            {source?.slice(0, 2).map((item, i) =>
                <Link target={item.link || isHttp(item.title) ? "_blank" : null} key={i} href={isHttp(item.title) ? item.identifier :item.link || '#'}>
                    <Card clickable={isHttp(item.title)? true : !!item.link}>
                        {isHttp(item.title) ? Url : identifierIsVideo(item.identifier) ? Video : Document}
                        <CardHeader>
                            <CardTitle>
                                {isHttp(item.title)? item.identifier : item.title}
                            </CardTitle>
                            <CardDescription>
                                {
                                isHttp(item.title) ? " " :
                                identifierIsVideo(item.identifier) ?
                                    formatIdentifier(item.identifier) :
                                    'Página: ' + item.identifier
                                }
                            </CardDescription>
                        </CardHeader>
                    </Card>
                </Link>
            )}

        </Container>
    );
};
