import React, {useState} from "react";
import RitaStatus from "../../RitaStatus/RitaStatus";
import {Container} from "./styles";
import {Link} from "react-router-dom";


export default function Construction(props) {
    let [status, setStatus] = useState('loading');
    setTimeout(() => setStatus('loaded'), 3000);

    return (
        <div className="App">
            <Container className="App-header">
                Não encontrado 404
                <RitaStatus medium status={status}/>
                <Link to={'3'}>
                    Saber mais sobre a verbeux
                </Link>
            </Container>
        </div>
    );
};
