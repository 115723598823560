import DataProvider from "./DataProvider";

let sessionId = "";

export const sendMessage = async (uuid, message, generative, fileList, clientData) => {
    if (!sessionId)
        await createSession();
    let route = "/dialog-manager/" + uuid + "/" + sessionId
    if (generative)
        route = "/generative-dialog-manager/" + uuid + "/" + sessionId;
    const data = await DataProvider(route, "PUT", message, null, {}, fileList, clientData);

    return data;
};

export const createSession = async (uuid, generative) => {
    let route = "/dialog-manager/" + uuid;
    if (generative)
        route = "/generative-dialog-manager/" + uuid;

    let {data} = await DataProvider(route, "POST", {}, null, {});
    sessionId = data.id;

    return data;
};
