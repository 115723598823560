import React from "react";
import { Card, CardHeader, CardTitle, Container} from "../Source/styles.js";

export default function Trigger({ trigger }) {
    return (
        <Container>
            <Card>
                <CardHeader>
                    <CardTitle>
                        Função: {trigger.function_name}
                    </CardTitle>
                </CardHeader>
            </Card>
        </Container>
    );
}
