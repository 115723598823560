import styled, {css, keyframes} from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    width: 60px;
    height: 60px;

    @media (max-width: 768px) {
        width: 50px;
        height: 50px;
    }
    @media (max-width: 468px) {
        width: 40px;
        height: 40px;
    }
`;

const BigBallAnimationLoading = keyframes({
    "0%": {
        width: "50%",
        height: "50%"
    },
    "50%": {
        width: "75%",
        height: "75%"
    },
    "100%": {
        width: "50%",
        height: "50%"
    }
});

const SmallBallAnimationLoading = keyframes({
    "0%": {
        width: "50%",
        height: "50%"
    },
    "20%": {
        width: "75%",
        height: "75%"
    },
    "60%": {
        width: "50%",
        height: "50%"
    }
});

const BigBallAnimation = keyframes({
    "0%": {
        width: "50%",
        height: "50%"
    },
    "50%": {
        width: "70%",
        height: "70%"
    },
    "100%": {
        width: "50%",
        height: "50%"
    }
});

const SmallBallAnimation = keyframes({
    "0%": {
        width: "50%",
        height: "50%"
    },
    "50%": {
        width: "60%",
        height: "60%"
    },
    "100%": {
        width: "50%",
        height: "50%"
    }
});


export const BigBall = styled.div`
      backface-visibility: initial;
      width: 75%;
      height: 75%;
      border-radius: 100%;
      background-color: ${props => props.theme.primary};
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 1s all ease; 
      ${props => props.status === "loading" && css`
        animation: ${BigBallAnimationLoading} 1.4s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
      `}
        ${props => props.status === "loaded" && css`
        animation: ${BigBallAnimation} 3s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
      `}
`;

export const SmallBall = styled.div`
    backface-visibility: initial;
    width: 50%;
    height: 50%;
    border-radius: 100%;
      background-color: ${({changeBackground}) => !changeBackground ? props => props.theme.background : props => props.theme.backgroundSecondary};
      transition: 1s all ease; 
      ${props => props.status === "loading" && css`
        animation: ${SmallBallAnimationLoading} 1.4s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
      `}
        ${props => props.status === "loaded" && css`
        animation: ${SmallBallAnimation} 3s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
      `}
`;
