import React, {useEffect, useState} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import Chatbot from "./components/Pages/Chatbot/Chatbot";
import {ThemeProvider} from "styled-components";
import colors from "./colors";
import Theme from "./context/theme";
import Construction from "./components/Pages/Construction/Construction";
import CustomColors from "./context/customColors";

function App() {
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
    const [themeColors, setThemeColors] = useState(colors.light);
    useEffect(() => {
        localStorage.setItem('theme', theme);
        setThemeColors(colors[theme]);
    }, [theme])

    return (
        <ThemeProvider theme={themeColors}>
            <Theme.Provider value={[theme, setTheme]}>
                <CustomColors.Provider value={[themeColors, setThemeColors]}>
                    <Router>
                        <Switch>
                            <Route path="/generative/:id"><Chatbot generative={true}/></Route>
                            <Route path="/:id"><Chatbot/></Route>
                            <Route path="*"><Construction/></Route>
                        </Switch>
                    </Router>
                </CustomColors.Provider>
            </Theme.Provider>
        </ThemeProvider>
    );
}

export default App;
