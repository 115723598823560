import React, {useState, useEffect, useContext, useRef, useCallback, useMemo} from 'react';
import {
    ChatbotContainer,
    Container,
    HeaderContainer,
    RitaName,
    MessagesContainer,
    InputContainer,
    InputField,
    Action,
    CustomLogo, HeaderTitleContainer,
    BtnImage,
    AttachmentBtn,
    PreviewImage,
    ImageDrawer,
    CloseButton,
    RowContainer,
    AudioInput,
    ImageName,
    CheckBtn,
    Counter
} from "./styles";
import RitaStatus from "../../RitaStatus/RitaStatus";

import TextMessage from "../../Messages/Text/TextMessage";
import ImageMessage from "../../Messages/Text/ImageMessage";
import AudioMessage from '../../Messages/Text/AudioMessage';

import RightArrow from "../../../assets/icons/send.svg";
import Microphone from "../../../assets/icons/microphone.svg";
import Attachment from "../../../assets/icons/attachment.svg";
import Pause from "../../../assets/icons/pause.svg";
import Trash from "../../../assets/icons/trash.svg";
import Close from "../../../assets/icons/Close.svg";
import Check from "../../../assets/icons/check.svg";
import Play from "../../../assets/icons/play.svg";
import Logo from '../../../assets/images/logo192.png';

import {sendMessage, createSession} from '../../../services/ChatbotComunication';
import {useLocation, useParams} from "react-router-dom";
import Theme from "../../../context/theme";
import CustomColors from "../../../context/customColors";
import Source from "../../Messages/Source/Source";
import Trigger from "../../Messages/Trigger/Trigger";

import { VoiceVisualizer, useVoiceVisualizer } from 'react-voice-visualizer';

export default function Chatbot({generative = false}) {
    const {id} = useParams();
    const { hash } = useLocation();
    const searchParams = new URLSearchParams(hash?.replace("#", "")?.replace("?", ""));
    const [inputMessage, setInputMessage] = useState('');
    const [status, setStatus] = useState('loading');
    const [icon, setIcon] = useState(Microphone);
    const [name, setName] = useState('Carregando...');
    const [avatar, setAvatar] = useState();
    const [timeline, setTimeline] = useState([]);
    const [inputDisabled, setInputDisabled] = useState(true);
    const [isMicrophone, setIsMicrophone] = useState(true);
    const [userUpdate, updateUser] = useState(0);
    const [botUpdate, updateBot] = useState(0);
    const [, setTheme] = useContext(Theme);
    const [themeColors, setThemeColors] = useContext(CustomColors);
    const [allowDocumentDisplay, setAllowDocumentDisplay] = useState(true);
    const [allowTriggerDisplay, setAllowTriggerDisplay] = useState(true);
    const [initialMessage, setInitialMessage] = useState('');
    const defaultMessage = [{data: "", type: "text"}];

    const fileInputRef = useRef(null);
    const messageContainerRef = useRef(null);
    const inputFieldRef = useRef(null);

    const [fileList, setFileList] = useState([]);
    const [imagePreview, setImagePreview] = useState('');
    const [imageDrawerVisible, setImageDrawerVisible] = useState(false);
    const [imageName, setImageName] = useState('');
    const [imageFile, setImageFile] = useState();
    const [imageCount, setImageCount] = useState(0);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const [isAudio, setIsAudio] = useState(false);
    const [isAudioChecked, setIsAudioChecked] = useState(false);
    const [audioDoneAndSend, setAudioDoneAndSend] = useState(false);
    const [audioMessageUrl, setAudioMessageUrl] = useState();
    const [canvaHeight, setCanvaHeight] = useState('45');
    const [barWidth, setBarWidth] = useState(2);

    const [fileFromBlob, setFileFromBlob] = useState();
    const [containerWidth, setContainerWidth] = useState(0);
    const containerRef = useRef(null);

    useEffect(() => {
        const updateWidth = () => {
            if (containerRef.current) {
                setContainerWidth(containerRef.current.offsetWidth);
            }
        };

        window.addEventListener('resize', updateWidth);

        updateWidth();

        return () => {
            window.removeEventListener('resize', updateWidth);
        };
    }, []);

    useEffect(() => {
        setCanvaHeight('45');
        setBarWidth(3);
        if(containerWidth < 768) {
            setCanvaHeight('35');
            setBarWidth(2);
        }
    }, [containerWidth]);

    const recorderControls = useVoiceVisualizer();
    const { startRecording,
        stopRecording,
        clearCanvas,
        isRecordingInProgress,
        isAvailableRecordedAudio,
        isPausedRecordedAudio,
        recordedBlob,
        error,
        recordingTime,
        duration,
        audioSrc
        } = recorderControls;

    useEffect(() => {
        createSession(id, generative).then((data) => {
            setStatus('loaded');
            if (data?.rita?.primaryColor)
                setThemeColors(old => ({...old, primary: data?.rita?.primaryColor}));
            setAvatar(data?.rita?.avatar || Logo);
            setName(data?.rita?.name || 'Verbeux');
            setAllowDocumentDisplay(data?.rita?.allowDocumentDisplay);
            setAllowTriggerDisplay(data?.rita?.allowTriggerDisplay);
            setInitialMessage(data.rita?.initialMessage);
            showMessage([{data: data.rita?.initialMessage, type: 'text'}],
                 data?.rita?.allowDocumentDisplay, data?.rita?.allowTriggerDisplay, false);
        }).catch(e => {
            console.error(e)
        })
    }, [id, generative]);

    const createFileFromBlob = useCallback((blob) => {
        if(!isAvailableRecordedAudio) return;

        const fileName = 'audio.webm';
        const fileOptions = { type: blob.type, lastModified: Date.now() };
        const newFile = new File([blob], fileName, fileOptions);

        setFileFromBlob(newFile);

    }, [isAvailableRecordedAudio]);

    useEffect(() => {
        createFileFromBlob(recordedBlob);
    }, [createFileFromBlob]);

    const addFile = (file) => {
        setFileList([...fileList, file]);
    }

    useEffect(() => {
        if(!fileFromBlob) return;
        addFile(fileFromBlob);
    }, [fileFromBlob]);

    const removeFile = (file) => {
        const newFileList = fileList.filter(item => item !== file);
        setFileList(newFileList);
    }

    useEffect(() => {
        if(!audioSrc) return;
        setAudioMessageUrl(audioSrc);
    }, [audioSrc])

    useEffect(() => {
        if (!error) return;

        console.error('Erro na gravação: ', error);
    }, [error]);

    useEffect(() => {
        if(isRecordingInProgress || isAudioChecked) {
            setIcon(RightArrow);
        } else {
            setIcon(Microphone);
        }
    }, [isRecordingInProgress]);

    const recorderProps = useMemo(() => ({
        height: canvaHeight,
        width: '100%',
        barWidth: barWidth,
        isControlPanelShown: false,
        isDefaultUIShown: false,
        isProgressIndicatorShown: false,
        fullscreen: true,
        isAudioProcessingTextShown: false,
        mainBarColor: `${themeColors.primary}`,
    }),[themeColors.primary, canvaHeight, barWidth]);

    function executeContext(context) {
        if (!context)
            return;

        if (context?.theme) {
            if (context.theme.includes('dark'))
                setTheme('dark');
            if (context.theme.includes('light'))
                setTheme('light');
        }
    }

    const scrollToBottomAndFocus = () => {
        if (messageContainerRef.current) {
            messageContainerRef.current.scrollTo(0, messageContainerRef.current.scrollHeight);
        }
        if (inputFieldRef.current) {
            inputFieldRef.current.focus();
        }
    };

    useEffect(() => {
        setTimeout(scrollToBottomAndFocus, 300);
    }, [userUpdate, botUpdate, status]);

    useEffect(() => {
        if (inputFieldRef.current) {
            inputFieldRef.current.focus();
        }
    }, [botUpdate]);

    async function botMessage(message, allowDocDisplay, allowTrigDisplay, fileList) {
        setStatus('loading');
        let clientData = {}
        for (const [key, value] of searchParams.entries()) {
            clientData[key] = value
        }

        let response = await sendMessage(id, message, generative, fileList, clientData);
        setStatus('loaded');
        await showMessage(response.response, allowDocDisplay, allowTrigDisplay);
        executeContext(response?.context);
        inputFieldRef.current.focus();
    }

    const getFilesObjects = (fileList) => {
        const files = fileList.map((item) => {
            let fileType = item.type.split("/")[0];
            if(fileType === "audio") {
                return {data: item.name, type: "audio", url: audioMessageUrl, audioDuration: counter(Math.round(duration))}
            }
            return {data: imageName, type: "image", src: imagePreview}
        })

        return files;
    }

    async function showMessage(incomingMessages, allowDocDisplay, allowTrigDisplay, client, fileList = []) {
        if (!incomingMessages || incomingMessages.length < 1) return;
        setInputDisabled(true);
        let messages = timeline;

        if(fileList.length > 0) {
            incomingMessages.push(...getFilesObjects(fileList))
        }

        for (const message of incomingMessages) {
            messages.push(
                <React.Fragment key={message.id}>
                    {message.type === "image" && (
                        <ImageMessage title={imageName} src={imagePreview} client={client}/>
                    )}
                    {(message.type === "text" && message.data) && (
                        <TextMessage message={message?.data} client={client}/>
                    )}
                    {(message.type === "reference" && allowDocDisplay) && (
                        <Source source={message?.data}/>
                    )}
                    {(message.type === "trigger" && allowTrigDisplay) && (
                        <Trigger trigger={message?.data}/>
                    )}
                    {message.type === "audio" && (
                        <AudioMessage duration={message.audioDuration}
                            src={message.url} client={client}/>
                    )}
                </React.Fragment>
            );
        }

        setTimeline(messages);

        if (client) {
            updateUser(!userUpdate);
            setIcon(Microphone);
            setIsMicrophone(true);
            setInputMessage('');
            await botMessage(incomingMessages[0]?.data, allowDocDisplay, allowTrigDisplay, fileList);
        } else updateBot(!botUpdate);

        setInputDisabled(false);
    };

    useEffect(() => {
        if (isDrawerOpen) {
            setImageDrawerVisible(true);
        } else {
            setImageDrawerVisible(false);
        }
    }, [isDrawerOpen]);

    const handleCloseDrawer = () => {
        setImagePreview('');
        setIsDrawerOpen(false);
        removeFile(imageFile);
        setImageCount(0);
    };

    const handleAttachmentClick = useCallback(() => {
        if (fileInputRef.current && imageCount === 0) {
            fileInputRef.current.click();
        }
    },[fileInputRef]);

    const handleClearAudio = () => {
        clearCanvas();
        setIsAudioChecked(false);
        setIsAudio(false);
        setIcon(Microphone);
        setIsMicrophone(true);
    };

    const handleDeleteAudio = useCallback(() => {
        handleClearAudio();
        if(fileFromBlob) {
            removeFile(fileFromBlob);
        }
    }, [fileFromBlob]);

    const handleAudioDone = () => {
        stopRecording();
        setIsAudioChecked(true);
        setIsMicrophone(false);
    }

    const handlePlayAudio = () => {
        recorderControls.togglePauseResume();
    };

    const sendAudioMessage = useCallback(async () => {
        handleCloseDrawer();
        handleClearAudio();
        await showMessage(defaultMessage, allowDocumentDisplay, allowTriggerDisplay, true, fileList)
            .catch((err) => {
                console.log('Erro no envio do áudio: ', err);
            });
        setFileList([]);
    }, [fileList, handleCloseDrawer, handleClearAudio, showMessage, allowDocumentDisplay, allowTriggerDisplay, defaultMessage]);


    const sendTextMessage = useCallback(async () => {
        handleCloseDrawer();
        setFileList([]);
        setInputMessage('');
        await showMessage([{data: inputMessage, type: "text"}], allowDocumentDisplay, allowTriggerDisplay, true, fileList)
            .catch((err) => {
                console.log('Erro no envio do texto: ', err);
            });
    },[handleCloseDrawer, inputMessage, fileList]);

    const inputProps = useMemo(() => ({
        disabled: inputDisabled,
        value: inputMessage,
        drawer: imageDrawerVisible,
        onKeyPress: (e) => {
            if(!inputMessage && fileList.length === 0 && e.key === "Enter") return;
            if (e.key === "Enter") {
                sendTextMessage();
            }
        },
        onChange: ({target}) => {
            setInputMessage(target.value);
            setIcon(RightArrow);
            setIsMicrophone(false);
            if (!target.value) {
                setIcon(Microphone);
                setIsMicrophone(true);
            }
        },
        placeholder: 'Tire sua dúvida'
    }),[inputDisabled, inputMessage, fileList, imageDrawerVisible]);

    const [imageWithAudio, setImageWithAudio] = useState(false);

    useEffect(() => {
        if(!isAvailableRecordedAudio) return;
        if(isAudioChecked) return;
        if(!fileFromBlob) return;
        if(fileList.length === 0) return;
        if(fileList[0].type !== "audio/webm;codecs=opus") {
            setImageWithAudio(true);
            return;
        }

        sendAudioMessage();
    }, [isAvailableRecordedAudio, fileList, isAudioChecked, fileFromBlob])

    useEffect(() => {
        if(!imageWithAudio) return;
        setImageWithAudio(false);
        sendAudioMessage();
    }, [imageWithAudio]);

    useEffect(() => {
        if(!isAudioChecked) return;
        if(!audioDoneAndSend) return;
        if(fileList[0].type !== "audio/webm;codecs=opus") {
            setImageWithAudio(true);
            return;
        }
        setAudioDoneAndSend(false);
        sendAudioMessage();
    }, [isAudioChecked, audioDoneAndSend])

    const handleSendButton = useCallback(() => {
        if (isMicrophone) {
            startRecording();
            setIsAudio(true);
            if(isRecordingInProgress) {
                stopRecording();
            }
        } else {
            if(isAudioChecked) {
                setAudioDoneAndSend(true);
            }

            if (inputMessage) {
                sendTextMessage();
            }
        }
    },[isRecordingInProgress, isMicrophone, isAudioChecked, inputMessage, sendTextMessage])

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageCount(imageCount + 1);
                setImagePreview(reader.result);
                setImageFile(file);
                addFile(file);
                setIsDrawerOpen(true);
                if(imageCount <= 1) {
                    setImageName(file.name);
                }
            };
            reader.readAsDataURL(file);
            event.target.value = '';
        }
    }

    const counter = (seconds) => {
        return `${seconds}s`;
    }
    return (
        <Container ref={containerRef}>
            <ChatbotContainer>
                <HeaderContainer>
                    <HeaderTitleContainer>
                        <CustomLogo src={avatar}/>
                        <RitaName>
                            <p>{name}</p>
                        </RitaName>
                    </HeaderTitleContainer>
                    <div>
                        <RitaStatus status={status}/>
                    </div>
                </HeaderContainer>
                <MessagesContainer id='messageContainer' ref={messageContainerRef} drawer={isDrawerOpen}>
                    {timeline.map((item, i) => <React.Fragment key={i}> {item} </React.Fragment>)}
                </MessagesContainer>
                <InputContainer>
                    <RowContainer>
                        {imageDrawerVisible && (
                            <ImageDrawer open={isDrawerOpen}>
                                <PreviewImage src={imagePreview} alt="Preview" />
                                <ImageName>
                                    {imageName}
                                </ImageName>
                                <CloseButton onClick={handleCloseDrawer}>
                                    <img alt={imageName} src={Close} style={{height: '12px', width: '12 px'}}/>
                                </CloseButton>
                            </ImageDrawer>
                        )}
                    </RowContainer>
                    <RowContainer>
                        {isAudio ?
                            <AttachmentBtn onClick={handleDeleteAudio}>
                                <BtnImage src={Trash} />
                            </AttachmentBtn> :
                            <AttachmentBtn disabled={imageCount === 1} onClick={handleAttachmentClick}>
                                <BtnImage src={Attachment} />
                            </AttachmentBtn> }
                        <CheckBtn onClick={isRecordingInProgress ? handleAudioDone : handlePlayAudio} isAudio={isAudio}>
                            <BtnImage src={isRecordingInProgress ? Check : (isPausedRecordedAudio ? Play : Pause)}/>
                        </CheckBtn>
                        <Counter isRecording={isRecordingInProgress || isAudioChecked}>
                            {isAudioChecked ? counter(Math.round(duration)) : counter(Math.round(recordingTime/1000))}
                        </Counter>
                        <input
                            type="file"
                            // accept="image/jpeg, image/png"
                            accept="*"
                            style={{ display: 'none' }}
                            onChange={handleImageUpload}
                            ref={fileInputRef}
                        />
                        {isAudio ?
                            <AudioInput drawer={isDrawerOpen}>
                                <VoiceVisualizer {...recorderProps} controls={recorderControls} />
                            </AudioInput> :
                            <InputField id={"inputField"} {...inputProps} ref={inputFieldRef}/>}
                        <Action onClick={handleSendButton}>
                            <BtnImage src={icon}/>
                        </Action>
                    </RowContainer>
                </InputContainer>
            </ChatbotContainer>
        </Container>
    );
}
