export default {
    light:{
        primary: "#454780",
        secondary: "#ffffff",
        background: "#ffffff",
        backgroundSecondary: "#f5f5f5",
        boxShadow: "0 0 16px rgba(0,0,0,0.15)",
    },
    dark: {
        primary: "#ffffff",
        secondary: "#454780",
        background: "#282c34",
        backgroundSecondary: "#161820",
        boxShadow: "0 0 4px rgba(0,0,0,0.2)",
    }
}
