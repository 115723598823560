import React, {useRef, useState} from "react";
import {MessageBox, MessageContainer, AudioContainer, AudioTimer, AudioBtn, BtnImage} from "./styles";
import Play  from "../../../assets/icons/play-white.svg";
import Pause from "../../../assets/icons/pause-white.svg";

export default function AudioMessage(props) {
    const { duration, src, imageColor } = props;
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const handleAudioPlayPause = () => {
        if (audioRef.current) {
            if (audioRef.current.paused) {
                audioRef.current.play();
                setIsPlaying(true);
            } else {
                audioRef.current.pause();
                setIsPlaying(false);
            }
        }
    };

    return (
        <MessageContainer {...props}>
            <MessageBox {...props}>
                <audio ref={audioRef} src={src} style={{ display: 'none' }} onEnded={() => setIsPlaying(false)}/>
                <AudioContainer {...props}>     
                    <AudioBtn onClick={handleAudioPlayPause}>
                        <BtnImage {...props} imageColor={imageColor} src={isPlaying ? Pause : Play}/>
                    </AudioBtn>
                    <AudioTimer {...props}>
                        {duration}
                    </AudioTimer>
                </AudioContainer>
            </MessageBox>
        </MessageContainer>
    );

};
