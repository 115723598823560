import React from "react";
import {MessageBox, MessageContainer,ImageContainer, ImageView, ImageTitle} from "./styles";

export default function ImageMessage(props) {
    let {title} = props;

    return (
        <MessageContainer {...props}>
            <MessageBox {...props}>
                <ImageContainer>
                   <ImageView {...props}/>
                    <ImageTitle {...props}>
                        {title}
                    </ImageTitle> 
                </ImageContainer>
            </MessageBox>
        </MessageContainer>
    );

};
